import React, { useEffect, useState } from 'react';

import './PaymentInfo.css'
import PaymentForm from '../PaymentForm/PaymentForm';
import localStorageService from '../../../utils/helpers/services/localStorageService';
import { IDENTITY_SERVICES_PRODUCTS } from '../../../utils/helpers/constants/localStorageKeys';







const PaymentInfo = () => {

    const [product, setProduct] = useState({})

    useEffect(()=>{
        setProduct(localStorageService.getProduct(IDENTITY_SERVICES_PRODUCTS.CARD_PRODUCT_SUBSCRIPTON));
    },[])

    return (
        <section className='PaymentInfo__main-section'>
            <div className='PaymentInfo__main-title-container'>
                <h1 className="PaymentInfo__title">Thank you for choosing to join our Community</h1>
                <div style={{ width : '100%', maxWidth : '40em', display: 'flex', flexDirection : 'column', alignItems : 'center', justifyContent : 'center', marginBottom : '0', padding : '.5em'}}>
                    <p style={{border : ' 3px solid #e7e7e7', padding : '.5em', borderRadius : '6px'}}> 
                    By placing your monthly recurring order of {product.subscriptionDescription} subscription
                    you will be charged ${product.subscriptionPrice} Now and every 30 days thereafter until you cancel your subscription. 
                    You will receive an electronic notification 5 to 7 days prior to your transaction and receipt after each successful transaction.
                    </p>
                </div>
            </div>
            <div className='PaymentInfo__payment-form-container'>
                <PaymentForm></PaymentForm>
            </div>
        </section>
    );
};

export default PaymentInfo;
