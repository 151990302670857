import React from "react";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import './ProductDescription.css';

const ProductDescription = ({ props }) => {
    const location = useLocation();
    let paragraphContent;


    console.log(location.pathname)
    //const { paragraphs, instructions } = props;


    switch (location.pathname) {    
        case '/':
            paragraphContent = (
                <>
                    <p style={{width : '100%'}}>
                        <b>
                            Cyber Sentinel is a monthly service designed to protect
                            against identity theft by offering a program to prevent,
                            recover and respond to victims of this type of digital crime.
                        </b>
                    </p>
                    <br />
                    <p style={{width : '100%'}}>
                        The plan eliminates the stress, anxiety and worry of surfing
                        the Internet or getting mugged by <b>providing 24/7/365 virtual
                        assistance.</b>
                    </p>
                    <br />
                    <p>
                        Don't become a victim, if a thief takes over your identity, it can
                        take many months to discover it, not to mention that it can&nbsp;
                        <b>take approximately 300 hours and up to 44 months to
                        correct the problems associated with it.</b>
                    </p>
                    <br />
                    <p>
                        Identity theft has become one of the most common crimes
                        today, affecting more than a billion Americans and costing
                        millions of dollars in losses. <b>Our top priority is to take care of
                            you and your family.
                        </b>          
                    </p>
                </>
            );
            break;

        case '/about-us':
            paragraphContent = (
                <>
                    <p>
                       <b>
                       Cyber Sentinel is connected to an identity protection and privacy
                       management service provider called Privacymaxx.
                       </b>
                    </p>
                    <br/>
                    <p>
                    Access your account by logging in or by visiting <b>portal.yourcybersentinel.com </b> 
                    and entering your account ID. Get an 
                    innovative service to help protect and secure your personal
                    information.
                    </p>
                    <br/>
                    <p>
                    Protect yourself and make identity theft a thing of the past 
                    with our <b> 24/7 expert assistance, security upgrades, and
                    protection tools.
                    </b>
                    </p>
                </>
            );
            break;

        default:
            paragraphContent = null;

    }

    return (
        <section className="ProductDescription__main-container">
            <div className="ProductDescription__explanation-container">
                <div className="ProductDescription__title-container">
                    <h1>{props.title}</h1>
                </div>
                <div className="ProductDescription__explanation-container text-left">
                    {paragraphContent}
                </div>
            </div>

            <div className="ProductDescription__img-container">
                <div className="ProductDescription__img-inner-container">
                    <img src={props.img} alt={props.title} className="ProductDescription__img" />
                </div>
            </div>
        </section>
    );
};

export default ProductDescription;