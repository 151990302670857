import React, { useEffect } from 'react';
import checkoutBannerImg from '../../utils/assets/shared/bg3.png';
import BannerSection from '../../shared/components/BannerSection/BannerSection';
import NavegationInfo from '../../shared/components/NavegationInfo/NavegationInfo';
import PaymentInfo from '../components/PaymentInfo/PaymentInfo';
import onbgImg from '../../utils/assets/checkout/onbg1.png';

const Checkout = () => {

    useEffect(()=>{
        window.scroll(0,0);
    },[])
    const bannerSectionInfo =
    {
       cardTitle: "Now you can invest in identity protection and security",
       cardButtonText: "",                    
       lengendBackground: checkoutBannerImg,
       cardImg : onbgImg
    }

    return (
        <React.Fragment>
           <BannerSection props={bannerSectionInfo}/>
           <NavegationInfo page="Checkout"/>
           <PaymentInfo/>
        </React.Fragment>
    );
};

export default Checkout;
            