import React from "react";

import BannerSection from "../../shared/components/BannerSection/BannerSection";
import ProductDescription from '../../shared/components/ProductDescription/ProductDescription';
import Benefit from "../components/Benefit/Benefit";
import SectionInfo from "../../shared/components/SectionInfo/SectionInfo";
import SubscriptionPlan from "../../shared/components/SubscriptionPlan/SubscriptionPlan";

import manDigitalIdentityBannerImg from '../../utils/assets/shared/bg1.png';
import protectProgramComputer from '../../utils/assets/home/protection-experts.png';
import hackersProtectedYourSystem from '../../utils/assets/shared/bg3.png';
import allProtectionToolsBannerImg from '../../utils/assets/shared/bg2.png';

import onbg1 from '../../utils/assets/home/onbg1.png';
import onbg2 from '../../utils/assets/home/onbg2.png';
import onbg3 from '../../utils/assets/home/onbg3.png';

const Home = props => {

    
    const bannerSectionInfo =
    [
        {
            cardTitle: "The best price for protecting your personal information",
            cardBannerPosition : 1,
            cardButtonText: "join us",
            lengendBackground: manDigitalIdentityBannerImg,
            cardImg : onbg1
        },
        {
            cardTitle: "Protect Yourself from Getting Your Identity Stolen",
            cardBannerPosition : 2,
            cardButtonText: "Start now",
            lengendBackground: hackersProtectedYourSystem,
            cardImg : onbg2
        },
        {
            cardTitle: "Recovering and restoring your identity has never been easier",
            cardBannerPosition : 3,
            cardButtonText: "Sign Up",
            lengendBackground: allProtectionToolsBannerImg,
            cardImg : onbg3
        }

    ]

    const productDescription = {
        title: "What is our service?",
        img: protectProgramComputer
    }

    // subscriptionTrialInfo: "Trial for 24 days at",
    // subscriptionTrialPrice : "3.49",
    const subscriptionInformation = [
        {       
            subscriptionType: "MONTH",
            subscriptionPrice: "44.99",
            subscriptionTrialInfo: "Our product",
            subscriptionTrialPrice : "",
            subscriptionDescription: "Monthly",
            subscriptionButtonText: "Buy"
        }, 
        {
            img: props.img,
            title : "Ready to relax and rest easy knowing you and your loved ones are covered 24/7?",
            description : "Find the protection and security you need to keep your identity and personal information safe from theft. Save money, time, and peace of mind knowing we have your back."
        }
    ]

 
    return (
        <React.Fragment>
            <BannerSection props={bannerSectionInfo[0]}/>
            <ProductDescription props={productDescription}/>
            <BannerSection props={bannerSectionInfo[1]}/>
            <Benefit/>
            <BannerSection props={bannerSectionInfo[2]}/>
            <SectionInfo title={"Become a member of our community"} color={"#fbb13b"}/> 
            <SubscriptionPlan  props={subscriptionInformation} />
        </React.Fragment>
    );
};

export default Home;