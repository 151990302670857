import React from "react";

import BenefitCard from "./components/BenefitCard/BenefitCard";

import warningSystemIcon from '../../../utils/assets/home/icons/warning-system-icon.png';
import insuranceIcon from '../../../utils/assets/home/icons/insurance-icon.png';
import protectionTrainingIcon from '../../../utils/assets/home/icons/protection-training.png';
import searchReputationIcon from '../../../utils/assets/home/icons/search-reputation-icon.png';
import blackMarket from '../../../utils/assets/home/icons/black-market.png' ;
import identifyRecoveryIcon from '../../../utils/assets/home/icons/identity-recovery-icon.png';
import emailSecutiryIcon from '../../../utils/assets/home/icons/email-security-icon.png';
import legalSupportIcon from '../../../utils/assets/home/icons/legal-support-icon.png';
import criminalRecordIcon from '../../../utils/assets/home/icons/criminal-record-icon.png';
import walletIcon from '../../../utils/assets/home/icons/wallet-icon.png';
import creditFreezesIcon from '../../../utils/assets/home/icons/credit-freezes.png';
import fraudAlertIcon from '../../../utils/assets/home/icons/fraud-alert-icon.png';

import './Benefit.css';
const Benefit = ({props}) => {
    
    const benefitCardInformation = [
        {
            cardTitle : "ID Early Warning System",
            cardIcon : warningSystemIcon,
            cardAbout : "If your identity is being used by someone else on the web, you can quickly detect and be alerted.",
            cardDescription : "Our innovative identity theft system scans hundreds of pieces of data at various random intervals, looking for signs of identity theft."
        },
        {
            cardTitle : "identity theft insurance",
            cardIcon : insuranceIcon,
            cardAbout : "Your coverage can help you recover between $25 K and $1 M for fraud-related expenses.",
            cardDescription : "AIG's Identity Theft Reimbursement Insurance covers notary fees, certified mail fees, lost wages, loan application fees, attorney fees and more."
        },
        {
            cardTitle : "Identity Protection Training Center",
            cardIcon : protectionTrainingIcon,
            cardAbout : "Learn how to protect your personal information and your loved ones online.",
            cardDescription : "Get all the information you need to enhance your online security and privacy settings through our news and tips from our advisors."
        },
        {
            cardTitle : "Online Reputation Search",
            cardIcon : searchReputationIcon,
            cardAbout : "In the digital world, you want to protect your good name and the people you love.",
            cardDescription : "Our program scans the Internet looking for derogatory information about you or your family, then classifies the information it finds for you to view easily and quickly."
        },
        {
            cardTitle : "Internet Black Market Monitoring",
            cardIcon : blackMarket,
            cardAbout : "Your data could be compromised by opening malicious e-mails, accepting cookies from suspicious websites, or downloading infected files.",
            cardDescription : "We constantly monitor the Deep Web for signs or leaks of sensitive personal information that may have been exposed by a potential hack."  
        },
        {
            cardTitle : "Identity Recovery and Restoration",
            cardIcon : identifyRecoveryIcon,
            cardAbout : "We are here to help you recover your identity, saving you time, money and headaches.",
            cardDescription : "We guide you through every step of the process, providing advice and assistance from our many experts in the field, or we handle the entire process for you."
        },
        {
            cardTitle : "Junk Mail Reduction",
            cardIcon : emailSecutiryIcon,
            cardAbout : "Tired of having your mailbox filled with junk mail and unsolicited advertisements?",
            cardDescription : "By removing your name from spam lists and information databases, our Green Mailbox service prevents the widespread use of your personal information."
        },
        {
            cardTitle : "Legal support",
            cardIcon : legalSupportIcon,
            cardAbout : "If you have any questions about how your personal information is being used on the Internet, or if you are in doubt about what legal steps you can take to recover your identity.",
            cardDescription : "Our team of privacy and security data specialists is available around the clock to help you with any questions you may have."
        },
        {
            cardTitle : "Criminal Background Report",
            cardIcon :criminalRecordIcon ,
            cardAbout : "Ever wonder if someone around you is who he or she claims to be?",
            cardDescription : "Now you can feel safer knowing that you can quickly check the complete criminal history of your neighbors, your family members, or your children's teachers. "
        },
        {
            cardTitle : "Lost Wallet Service",
            cardIcon : walletIcon,
            cardAbout : "Eliminate the stress of reporting your cards if your wallet or purse is stolen.",
            cardDescription : "Our service makes it harder for criminals to steal your money by keeping your critical financial information safe in one place, giving you time to contact financial institutions quickly."
        },
        {
            cardTitle : "Credit Security Freezes",
            cardIcon : creditFreezesIcon,
            cardAbout : "Block creditors from accessing your credit information without your permission by locking your accounts.",
            cardDescription : "If you have reason to believe that your personal information or identity has been stolen, a credit freeze can help protect you from fraud by placing a freeze on each of your credit profiles. "
        },
        {
            cardTitle : "Fraud Alert Assistance 24-7",
            cardIcon : fraudAlertIcon,
            cardAbout : "Anyone concerned about identity theft needs to focus on protecting their credit profile.",
            cardDescription : "Learn how to set credit fraud alerts on your accounts with step-by-step instructions from our experts. Fraud alerts can be one of your best identity protection tools."
        },

    ]


    return (
     <section className="Benefit__main-contiainer">
        <div className="Benefit__inner-contianer">
            {
                benefitCardInformation.map((cardInfo, index)=>(
                    <BenefitCard key={index} props={cardInfo}/>
                ))
            }
        </div>
     </section>
    );
};

export default Benefit; 