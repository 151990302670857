import React from 'react';

import './SectionInfo.css';

const SectionInfo = (props) => {
    return (
        <section className="SectionInfo__main-section-container">
            <div className="SectionInfo__title-container" style={{ backgroundColor: `${props.color}` }}>
                <h4 className="SectionInfo__title" >{props.title}</h4>
            </div>
        </section>
    );
} 
export default SectionInfo;