import React, { useEffect } from "react";

import BannerSection from '../../shared/components/BannerSection/BannerSection';
import NavegationInfo from '../../shared/components/NavegationInfo/NavegationInfo';
import ProductDescription from '../../shared/components/ProductDescription/ProductDescription';
import Faq from '../components/Faq/Faq';
import SectionInfo from "../../shared/components/SectionInfo/SectionInfo";


import aboutUsBannerImg from '../../utils/assets/shared/bg2.png';
import idRegisterImg from '../../utils/assets/aboutUs/id-register.png';
import onbgImg from '../../utils/assets/aboutUs/onbg1.png'


const AboutUs = props => {
   useEffect(()=>{
      window.scroll(0,0);
   },[])
    const bannerSectionInfo =
     {
        cardTitle: "Managing the protection of your personal information has never been easier",
        cardButtonText: "Join us",
        lengendBackground: aboutUsBannerImg,
        cardImg : onbgImg
     }

     const productDescription = {
        title : "Learn more about us",
        img : idRegisterImg
     }
          
    return(
        <React.Fragment>
           <BannerSection props={bannerSectionInfo}/>
           <NavegationInfo page="About us"/>
           <ProductDescription props={productDescription} />
            {/* <SectionInfo title={'FQA'} color={'#0f60bd'}/> */}
           <Faq/>
        </React.Fragment>
    );
};

export default AboutUs;