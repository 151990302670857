import React from "react";

import './CallUsButton.css';

const CallUsButton = props => {
    const cellphone = "8884743512";
    const handleButtonClick = () => {
        window.location.href = `tel:${cellphone}`;
      };
    return(
   
         <button className="call-us-button" onClick={handleButtonClick} style={{fontSize: '1.1rem', fontWeight: '700', backgroundColor : 'none'}}>
        {props.phonenumber}
        </button>
   
    );
};

export default CallUsButton;